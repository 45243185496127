import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import '../assets/css/Home.css';
import logo from '../assets/images/help-ace-logo.png';

class Home extends React.Component{
    constructor(props) {
        super(props); 
        this.state = {
        };
    }

render(){
    return <Container>
        <Row className="full_width">
            <Col>
                <img src={logo} alt="app-logo" className="home-logo"/>
            </Col>
            <Col>
                <p className="title">
                    <strong>
                        Welcome to&nbsp;
                        <span className="color_yellow">A</span>
                        <span className="color_orange">C</span>
                        <span className="color_red">E</span>
                        -U
                    </strong>
                </p>
                <p className="content">
                    <strong className="bigger_font color_yellow">A</strong>lways 
                    <strong className="bigger_font color_orange"> C</strong>are and 
                    <strong className="bigger_font color_red"> E</strong>ncourage-U to speak your truth, make your voice heard, and share your stories in a positive supportive community of inclusion.
                </p>
                {/* <p className="download_content">
                    Download App from 
                </p>
                <Row>
                    <div className="bordered_box">
                        <Row>
                            <Col xs={5} md={5}>
                                <img src={apple} alt="app-store" className="download_app" />
                            </Col>
                            <Col xs={7} md={7}>
                                <Row><div className="download_on">Download On </div></Row>
                                <Row><div className="store"><strong>App Store</strong></div> </Row>
                            </Col>
                        </Row>
                    </div>
                    <span>&nbsp;</span>
                    <div className="bordered_box">
                        <Row>
                            <Col xs={5} md={5}>
                                <img src={playstore} alt="play-store" className="download_app"/>
                            </Col>
                            <Col xs={7} md={7}>
                                <Row><div className="download_on">Get it On</div></Row>
                                <Row><div className="store"><strong>Google Play</strong></div> </Row>
                            </Col>
                        </Row>
                    </div>
                </Row> */}
            </Col>
        </Row>
    </Container>;
}
}
export default Home;
